import React, { useState, createContext, useRef } from 'react';
import PropTypes from 'prop-types';

export const NavPanelContext = createContext(null);

const NavPanelContextProvider = ({ children }) => {
  const [isClickOnPanel, setIsClickOnPanel] = useState(false);
  const myPanel = useRef();
  const myMenuButton = useRef();

  const handleClose = () => setIsClickOnPanel(false);
  const handleOpen = () => setIsClickOnPanel(true);

  const handleClickOutside = (e) => {
    if (
      (myPanel.current && !myPanel.current.contains(e.target)) ||
      (myMenuButton.current && myMenuButton.current.contains(e.target))
    ) {
      handleClose();
    }
  };

  const handleClickInside = handleOpen;

  return (
    <NavPanelContext.Provider
      value={{ isClickOnPanel, myPanel, handleClickOutside, handleClickInside, handleClose, handleOpen }}
    >
      {children}
    </NavPanelContext.Provider>
  );
};

NavPanelContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavPanelContextProvider;
