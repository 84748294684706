import React, { forwardRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import PropTypes from 'prop-types';

const menu = require('./menu.json');

const panel = { open: { x: 0, transition: { type: 'tween' } }, closed: { x: -300 } };

const NavPanel = forwardRef(({ isOpen, onClose }, ref) => {
  const { pathname } = useLocation();
  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <motion.div
            layout
            initial="closed"
            animate="open"
            exit="closed"
            variants={panel}
            ref={ref}
            className="lg:hidden fixed top-0 bottom-0 left-0 h-full bg-white shadow-lg z-40 pl-6 pr-6 pt-10"
          >
            {menu.map((item) => (
              <Link key={item.path} to={item.path} className="hover:text-gray-500 transition duration-150 m-auto">
                <button
                  type="button"
                  onClick={onClose}
                  className={`block my-5 pr-32 font-semibold px-2 py-1 uppercase text-sm ${
                    pathname === item.path ? 'text-gray-500' : ''
                  }`}
                >
                  {item.name}
                </button>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
});
NavPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NavPanel;
