/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

const AnimeDiv = (props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { delay = 1 } = props;

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.4 * delay,
            type: 'tween',
          },
        },
        hidden: {
          opacity: 0,
          y: 50,
          transition: {
            delay: 0.4 * delay,
            type: 'tween',
          },
        },
      }}
      {...props}
    >
      {props.children}
    </motion.div>
  );
};

AnimeDiv.propTypes = {};

export default AnimeDiv;
