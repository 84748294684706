import React from 'react';
import AnimeDiv from '@components/anime/AnimeDiv';
import ContactForm from '@components/contact/ContactForm';
import ProjectCellGroup from '@components/project/ProjectCellGroup';

const Contact = () => (
  <>
    <AnimeDiv
      className="container px-5 m-auto pb-14"
      style={{
        paddingTop: 100,
      }}
    >
      <p className="uppercase mb-1">Recent</p>
      <p className="text-2xl font-semibold">Projects</p>
    </AnimeDiv>
    <div
      className="container m-auto px-5 grid md:grid-cols-3"
      style={{
        paddingBottom: 50,
      }}
    >
      <div className="md:mr-20">
        <ProjectCellGroup className="mb-20" />
      </div>
      <AnimeDiv className="w-full md:col-span-2">
        <div className="p-10 bg-white border-black border-4">
          <ContactForm />
        </div>
      </AnimeDiv>
    </div>
  </>
);

export default Contact;
