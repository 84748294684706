import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ContactCell = ({ src, text, type, iconHeight = 'h-8' }) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <div
      className="flex flex-col justify-start items-center"
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <img src={src} alt={text} className={`my-5 ${iconHeight} m-auto`} />
      {text.map((line) => (
        <a
          href={
            type === 'phone'
              ? `tel:${text[0]}`
              : type === 'email'
              ? `mailto:${text[0]}`
              : `http://maps.google.com/?q=${text.toString()}`
          }
          className={`${onHover ? 'text-blue-400' : ''} transition duration-150`}
          key={line}
        >
          {line}
        </a>
      ))}
    </div>
  );
};

ContactCell.propTypes = {
  src: PropTypes.string.isRequired,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.string.isRequired,
  iconHeight: PropTypes.string,
};

export default ContactCell;
