import React from 'react';
import PropTypes from 'prop-types';
import AnimeDiv from '@components/anime/AnimeDiv';
import Typewriter from 'typewriter-effect';
import { motion } from 'framer-motion';

const Header = ({ src, title }) => (
  <div className="relative text-white w-full h-my-header flex flex-col justify-center items-center gradient-object">
    <motion.img
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          opacity: 1,
          transition: {
            delay: 0.4,
            type: 'tween',
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            delay: 0.4,
            type: 'tween',
          },
        },
      }}
      className="absolute w-full h-full object-cover opacity-75"
      src={src}
      alt="header"
    />
    <AnimeDiv delay={3} className="uppercase md:normal-case text-3xl md:text-4xl md:mt-64 container px-5 z-10">
      {title.map((line) => (
        <p key={line} className="my-1 md:w-1/2">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString(line || '')
                .pauseFor(2000)
                .start();
            }}
          />
        </p>
      ))}
    </AnimeDiv>
  </div>
);

Header.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Header;
