import React, { forwardRef, useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ScrollContext } from '@contexts/scrollContext';
import AnimeDiv from '../../anime/AnimeDiv';

const menu = require('./menu.json');

const Nav = forwardRef(({ onToggle }, ref) => {
  const { pathname = '' } = useLocation();
  const { isScrollOnTop } = useContext(ScrollContext);
  const color = pathname === '/contact-us' || pathname.indexOf('/projects') > -1 ? 'black' : 'white';
  return (
    <>
      <div
        className={`fixed z-20 top-0 left-0 right-0 flex py-4 px-4 md:px-6 justify-center items-center ${
          color === 'black' || !isScrollOnTop ? 'bg-white shadow-b shadow-md text-black' : 'text-white'
        } transition duration-300`}
      >
        <div className="container flex md:px-5">
          <Link to="/">
            <img
              src={`/assets/img/logo-${color && isScrollOnTop ? color : 'black'}.png`}
              alt="logo"
              className="h-8 mr-20 object-contain"
            />
          </Link>
          <div className="hidden lg:flex">
            {menu.map((item) => (
              <Link to={item.path} key={item.path} className="transition duration-150 m-auto">
                <button
                  type="button"
                  className={`mx-1 px-2 py-1 uppercase text-xs ${
                    pathname === item.path || (item.path !== '/' && pathname.indexOf(item.path) > -1)
                      ? 'normal-underline'
                      : 'custom-underline'
                  }`}
                >
                  {item.name}
                </button>
              </Link>
            ))}
          </div>
          <div className="flex-1 lg:hidden" />
          <button
            type="button"
            className="mr-0 px-2 lg:hidden hover:text-gray-500 transition duration-300"
            onClick={onToggle}
            ref={ref}
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
      {isScrollOnTop && (
        <AnimeDiv
          className="fixed h-64 top-0 left-0 right-0 bg-gradient-to-b from-gray-600 to-gray-200"
          style={{
            zIndex: -1,
          }}
        />
      )}
    </>
  );
});

Nav.propTypes = {
  onToggle: PropTypes.func.isRequired,
};

export default Nav;
