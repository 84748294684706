/* eslint-disable no-empty-pattern */
import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AnimeDiv from '@components/anime/AnimeDiv';

const menu = require('../nav/menu.json');

const BackToTopButton = () => {
  const handleBackToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  return (
    <button
      type="button"
      onClick={handleBackToTop}
      className="absolute bottom-0 right-0 m-8 md:m-10 p-3 md:p-4 bg-gray-500 text-gray-100 hover:shadow-lg hover:bg-gray-500 transition duration-300"
    >
      <svg
        className="w-6 h-6 md:w-8 md:h-8"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
      </svg>
    </button>
  );
};

const Footer = forwardRef(({}, ref) => {
  const { pathname = '' } = useLocation();
  return (
    <div
      style={{
        zIndex: -2,
      }}
      className="fixed bottom-0 left-0 right-0 bg-gray-200"
      ref={ref}
    >
      <div className="container m-auto px-5 grid py-10 lg:grid-cols-5 gap-6 text-sm">
        <AnimeDiv className="flex lg:flex-col text-black">
          {menu.slice(1, menu.length).map((item) => (
            <Link to={item.path} key={item.path}>
              <button
                className={`block font-semibold mb-2 py-1 px-1 text-xs md:text-md sm:mr-5 transition duration-300 ${
                  pathname === item.path || (item.path !== '/' && pathname.indexOf(item.path) > -1)
                    ? 'normal-underline'
                    : 'custom-underline'
                }`}
                type="button"
              >
                <span className="uppercase">{item.name}</span>
              </button>
            </Link>
          ))}
        </AnimeDiv>
        <AnimeDiv>
          <p className="mb-2 font-semibold">Office Address</p>
          <div className="flex lg:block">
            <p>106-2691 Viscount Way</p>
            <p>Richmond, BC V6V 2R5</p>
            <p>(Near Home depot)</p>
          </div>
        </AnimeDiv>
        <AnimeDiv>
          <p className="mb-2 font-semibold">Social</p>
          <div className="flex lg:block">
            <p>
              <a
                className="mr-10"
                href="https://www.facebook.com/Powerwell-Publicity-and-Display-124072951099747/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
            <a href="https://www.instagram.com/powerwellsigns/" target="_blank" rel="noreferrer">
              Instagram
            </a>
          </div>
        </AnimeDiv>
        <AnimeDiv>
          <p className="mb-2 font-semibold">Office Hours</p>
          <p>MONDAY 9:00 - 6:00</p>
          <p>TUESDAY 9:00 - 6:00</p>
          <p>WEDNESDAY 9:00 - 6:00</p>
          <p>THURSDAY 9:00 - 6:00</p>
          <p>FRIDAY 9:00 - 6:00</p>
          <p>SATURDAY closed</p>
          <p>SUNDAY closed</p>
        </AnimeDiv>
        <AnimeDiv>
          <p className="mb-2 font-semibold">Philosophy</p>
          <p className="pr-32 lg:pr-0 lg:mb-20">
            Every brand needs a strong first impression. It is our goal to deliver your message in a way stands out.
            Defining business with connection, flare, creativity and excitment in a blink of an eye.
          </p>
        </AnimeDiv>
        <BackToTopButton />
        <img src="/assets/img/vrca.png" alt="vrca" className="max-h-9" />
      </div>
    </div>
  );
});

Footer.propTypes = {};

export default Footer;
