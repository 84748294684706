import React from 'react';
import ReactDOM from 'react-dom';
import ScrollContextProvider from '@contexts/scrollContext';
import NavPanelContextProvider from '@contexts/panelContext';
import App from '~/App';
import '~/tailwind.css';

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

ReactDOM.render(
  <React.StrictMode>
    <ScrollContextProvider>
      <NavPanelContextProvider>
        <App />
      </NavPanelContextProvider>
    </ScrollContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
