import React from 'react';
import ContactCell from './ContactCell';

const ContactSection = () => (
  <>
    <div className="mb-14">
      <p className="uppercase mb-1 text-xs md:text-sm">CONTACT</p>
      <p className="text-xl md:text-2xl font-semibold">Feel free to contact us</p>
    </div>
    <div className="grid md:grid-cols-3 gap-8 mb-20">
      <ContactCell
        src="/assets/icon/map.png"
        text={['106-2691 Viscount Way', 'Richmond, BC V6V 2R5', '(Near Home depot)']}
        type="map"
      />
      <ContactCell src="/assets/icon/phone.png" text={['(604) 278-0860']} type="phone" iconHeight="h-10" />
      <ContactCell src="/assets/icon/mail.png" text={['jin@powerwell.ca']} type="email" />
    </div>
  </>
);

ContactSection.propTypes = {};

export default ContactSection;
