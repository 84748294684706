/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import AnimeDiv from '@components/anime/AnimeDiv';
import ProjectCell from './ProjectCell';

const ProjectCellGroup = ({ className = '' }) => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    setData(await fetch('/assets/json/ProjectData.json').then((response) => response.json()));
  }, []);

  return data ? (
    <AnimeDiv className={`grid gap-16 ${className}`}>
      {data
        .filter((project) => project.imageData && project.imageData.length > 0)
        .sort((d1, d2) => (d2.order || 2) - (d1.order || 1))
        .slice(0, 3)
        .map((project) => (
          <ProjectCell
            key={project.id}
            src={project.imageData[0].image}
            text={`${project.title || ''} | ${Number.parseInt(project.year, 10) || ''}`}
            address={[project.address1 || '', project.address2 || '']}
            type={`Type: ${project.type || ''}`}
            id={project.id || ''}
          />
        ))}
    </AnimeDiv>
  ) : (
    <></>
  );
};

ProjectCellGroup.propTypes = {};

export default ProjectCellGroup;
