import React, { useContext, useRef, useState, useLayoutEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import Home from '@pages/home/Home';
import Contact from '@pages/contact/Contact';
import Partners from '@pages/partners/Partners';
import Projects from '@pages/projects/Projects';
import Services from '@pages/services/Services';
import About from '@pages/about/About';
import Nav from '@components/layout/nav/Nav';
import NavPanel from '@components/layout/nav/NavPanel';
import { NavPanelContext } from '@contexts/panelContext';
import Footer from '@components/layout/footer/Footer';
import ScrollToTop from '@utils/ScrollToTop';

const App = () => {
  const { isClickOnPanel, myPanel, myMenuButton, handleClickOutside, handleClose, handleOpen } = useContext(
    NavPanelContext
  );

  // for detecting footer height
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  // for render footer height initial
  useLayoutEffect(() => {
    if (footerRef.current && footerRef.current.clientHeight) {
      setFooterHeight(footerRef.current.clientHeight);
    }
  }, [footerRef]);

  // for render footer height when window update
  useLayoutEffect(() => {
    const handleResize = () => {
      if (footerRef.current && footerRef.current.clientHeight) {
        setFooterHeight(footerRef.current.clientHeight);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className="w-full min-h-screen" onClick={handleClickOutside}>
      <BrowserRouter>
        <ScrollToTop />
        <Footer ref={footerRef} />
        <div
          className="bg-white"
          style={{
            marginBottom: footerHeight,
          }}
        >
          <Nav ref={myMenuButton} onToggle={isClickOnPanel ? handleClose : handleOpen} />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/:_id" component={Projects} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/contact-us" component={Contact} />
            <Redirect to="/" />
          </Switch>
          <NavPanel ref={myPanel} isOpen={isClickOnPanel} onClose={handleClose} />
        </div>
      </BrowserRouter>
    </div>
  );
};

App.propTypes = {};

export default App;
