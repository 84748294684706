import React from 'react';
import Header from '@components/layout/header/Header';
import AnimeDiv from '@components/anime/AnimeDiv';

const HEADER = {
  src: '/assets/img/about/about-header.png',
  title: ['Spark your image', 'Put the spotlight on your store. '],
};

const About = () => {
  const { src, title } = HEADER;
  return (
    <>
      <Header src={src} title={title} />
      <div className="container m-auto px-5 pb-5">
        <AnimeDiv className="md:mr-64 my-10 md:my-28">
          <p className="font-semibold mb-5 md:mb-8 uppercase text-xs md:text-sm">About Us</p>
          <p className="md:text-2xl mb-10">
            Our commitment to excellence has allowed us to expand our customer base covering and illuminating the
            deverified needs of over 1600 satisfied business across the Greater Vancouver Region alone, established in
            2012.
          </p>

          <p className="md:text-2xl mb-8">
            We provide you with start to finish service including; working with you on your visual identity, concepts
            and artwork, permit application and installation.
          </p>
        </AnimeDiv>
        <AnimeDiv className="mb-8 grid">
          <div className="w-full overflow-hidden">
            <img
              src="/assets/img/about/img-1.png"
              alt="img-1"
              className="mb-8 w-full h-full hover:scale-110 scale-100 transform transition duration-200 object-cover"
            />
          </div>
        </AnimeDiv>
        <AnimeDiv className="my-8 md:my-28 grid md:grid-cols-5 gap-4 md:gap-10">
          <div className="md:col-span-2">
            <p className="hidden md:flex mb-5 md:mb-8 uppercase text-xs md:text-sm">PHILOSOPHY</p>
            <p className="text-xl md:text-2xl font-semibold mb-8">
              Every brand needs a strong first impression. It is our goal to deliver your message in a way stands out.
            </p>
          </div>
          <div className="md:col-span-3">
            <p className="mb-6">
              Defining business with connection, flare, creativity and excitment in a blink of an eye.
            </p>
            <div className="md:flex md:space-x-6 items-center mb-3">
              <img src="/assets/img/about/e509252.png" className="w-28 m-auto py-4" alt="e509252" />
              <p className="mb-6">
                At Powerwell, professionalism has always been our priority. We are UL (Underwriters Laboratories -
                nationally recognized Standards for Safety) Authorized signage company, which means you can trust us
                with your sign.
              </p>
            </div>

            <p className="mb-6">
              We welcome any challenges the customers bring. Whether is an one of the kind designs or limited budget, we
              have you covered. From our wide-ranging services and materials, we will help you find the best solution.
            </p>
          </div>
        </AnimeDiv>
        <AnimeDiv className="my-8 md:my-32 grid md:grid-cols-2 gap-10 md:gap-20">
          <div className="w-full overflow-hidden">
            <img
              src="/assets/img/about/img-2.png"
              alt="img-2"
              className="w-full h-full hover:scale-110 scale-100 transform transition duration-200"
            />
          </div>
          <div className="w-full overflow-hidden">
            <img
              src="/assets/img/about/img-3.png"
              alt="img-3"
              className="w-full h-full hover:scale-110 scale-100 transform transition duration-200"
            />
          </div>
        </AnimeDiv>
      </div>
    </>
  );
};

export default About;
