import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ScrollContext = createContext();

const ScrollContextProvider = ({ children }) => {
  const [isScrollOnTop, setIsScrollOnTop] = useState(true);
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrollOnTop(false);
    } else {
      setIsScrollOnTop(true);
    }
  };
  useState(() => {
    if (handleScroll) {
      window.addEventListener('scroll', handleScroll, true);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return <ScrollContext.Provider value={{ isScrollOnTop }}>{children}</ScrollContext.Provider>;
};

ScrollContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ScrollContextProvider;
