/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ReactTimeout from 'react-timeout';
import { SwiperContext } from '@contexts/swiperContext';
import Typewriter from 'typewriter-effect';

const getHeaderVariants = (delay = 1) => ({
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2 * delay,
      type: 'tween',
    },
  },
  hidden: {
    opacity: 0,
    x: 30,
    transition: {
      delay: 0.1 * delay,
      type: 'tween',
    },
  },
});

const SwiperContainer = ({ setTimeout, clearTimeout }) => {
  const { targetKey, setTargetKey, currentIndex, data = [] } = useContext(SwiperContext);
  const header = data[currentIndex] || {};

  const timerToClear = useRef(null);

  // useEffect(() => {
  //   const timerId = window.setInterval(() => {
  //     console.log('timer');
  //   }, 5000);

  //   return window.clearInterval(timerId);
  // }, []);

  const handleOnClickPrev = () => {
    if (currentIndex > 0) {
      setTargetKey(data[currentIndex - 1].id);
    } else {
      setTargetKey(data[data.length - 1].id);
    }
  };

  const handleOnClickNext = () => {
    if (currentIndex < data.length - 1) {
      setTargetKey(data[currentIndex + 1].id);
    } else {
      setTargetKey(data[0].id);
    }
  };

  // timeout with ref to keep one timer always
  useEffect(() => {
    if (data.length > 0) {
      clearTimeout(timerToClear.current);
      timerToClear.current = setTimeout(() => {
        handleOnClickNext();
      }, 7000);
    }
  }, [currentIndex, timerToClear.current]);

  const PrevButton = ({ className = '' }) => (
    <button
      type="button"
      // disabled={currentIndex <= 0}
      className={`${className} hover:opacity-70 m-auto px-2 py-2 md:px-5 md:py-5 disabled:opacity-50 disabled:cursor-not-allowed`}
      onClick={handleOnClickPrev}
    >
      <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );

  const NextButton = ({ className = '' }) => (
    <button
      type="button"
      className={`${className} hover:opacity-70 m-auto px-2 py-2 md:px-5 md:py-5 disabled:opacity-50 disabled:cursor-not-allowed`}
      // disabled={currentIndex >= data.length - 1}
      onClick={handleOnClickNext}
    >
      <svg className="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <div className="flex flex-row absolute top-0 bottom-0 left-0 right-0 text-white z-10" key={targetKey}>
        <PrevButton className="hidden md:flex" />
        <div className="flex-1 flex flex-col justify-center items-start px-5 sm:items-center sm:px-20 md:items-end md:pl-0 md:pr-20 md:text-right">
          <motion.p
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={getHeaderVariants()}
            className="text-sm mb-4"
          >
            Year {Number.parseInt(header.year, 10) || ''}
          </motion.p>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={getHeaderVariants(1.5)}
            className="text-3xl mb-4"
          >
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString(header.title || '')
                  .pauseFor(2500)
                  .start();
              }}
            />
          </motion.div>

          <motion.p
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={getHeaderVariants(1.4)}
            className="md:w-64"
          >
            {(header.desp && `${header.desp.toString().substring(0, 150)}...`) || ''}
          </motion.p>
          <div className="absolute left-0 bottom-28 flex md:hidden w-full justify-between">
            <PrevButton className="ml-2" />
            <NextButton className="mr-2" />
          </div>
        </div>

        <div className="hidden md:flex w-64 flex-col justify-center items-start mr-20 my-auto md:border-l md:border-thin border-white">
          <div className="flex-1" />
          {data.map((dt) => (
            <button
              key={dt.id}
              type="button"
              className="relative block hover:opacity-70 px-2 my-4 pl-6"
              onClick={() => setTargetKey(dt.id)}
            >
              <span className="whitespace-nowrap">{dt.title}</span>
              {targetKey === dt.id && (
                <div className="absolute h-full w-20 left-0 top-0 flex items-center">
                  <div className="rounded-full bg-white my-auto" style={{ height: 5, width: 5, marginLeft: -3 }} />
                </div>
              )}
            </button>
          ))}
          <div className="flex-1" />
        </div>

        <NextButton className="hidden md:flex" />
      </div>
    </AnimatePresence>
  );
};

SwiperContainer.propTypes = {};

export default ReactTimeout(SwiperContainer);
