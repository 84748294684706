import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';

import 'swiper/swiper.scss';

const CustomSwiper = ({ data, activeKey = '', onIndexChange = () => {} }) => {
  const swiperRef = useRef(null);
  const params = {
    slidesPerView: 'auto',
    centeredSlides: true,
    slideToClickedSlide: true,
    on: {
      slideChangeTransitionStart: () => {
        if (swiperRef.current && swiperRef.current.swiper) {
          onIndexChange(swiperRef.current.swiper.activeIndex);
        }
      },
    },
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Swiper {...params} activeSlideKey={activeKey.toString()} ref={swiperRef}>
      {data.map((header) => (
        <div key={header.id} className="relative gradient-object">
          {header.component}
        </div>
      ))}
    </Swiper>
  );
};

CustomSwiper.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeKey: PropTypes.string,
  onIndexChange: PropTypes.func,
};

export default CustomSwiper;
