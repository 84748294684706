import React, { useEffect, useState } from 'react';
import Header from '@components/layout/header/Header';
import ContactSection from '@components/contact/ContactSection';
import AnimeDiv from '@components/anime/AnimeDiv';
import EffectGrid from '@components/grid/EffectGrid';
import Qna from '@components/qna/Qna';

const HEADER = {
  src: '/assets/img/services/services-header.png',
  title: ['Powerwell provides professional and innovative light solution for all businesses.'],
};

const Services = () => {
  const { src, title } = HEADER;
  const [data, setData] = useState([]);

  useEffect(async () => {
    setData(await fetch('/assets/json/EffectGridDataServices.json').then((response) => response.json()));
  }, []);

  return (
    <>
      <Header src={src} title={title} />
      <div className="">
        <AnimeDiv className="my-10 md:my-24 grid lg:grid-cols-2 gap-10 lg:gap-0">
          <div className="flex justify-center items-center overflow-hidden w-100vw h-100vw md:w-full md:h-full m-auto p-5 md:p-0">
            <img
              src="/assets/img/services/img-1.png"
              alt="img-1"
              className="w-full h-full md:hover:scale-110 scale-100 transform transition duration-200 object-cover"
            />
          </div>
          <div className="lg:px-28 px-5">
            <p className="font-semibold md:text-xl mb-8 uppercase">Services</p>
            <p className="text-xl md:text-2xl mb-1">Creative Design</p>
            <p className="md:text-lg mb-8">
              Need design for your signage? We provide innovative design services to help you create the perfect look
              for your business.
            </p>
            <p className="text-xl md:text-2xl mb-1">Permit Application</p>
            <p className="md:text-lg mb-8">
              Permit Application can be frustrating. We can help you apply for permits anywhere in B.C. With our
              experience, the process will be simple and clear.
            </p>
            <p className="text-xl md:text-2xl mb-1">Fabrication</p>
            <p className="md:text-lg mb-8">
              We are constantly mastering our craftmanship, exploring new material and making investment for the latest
              technology, to manufacture high quality signage and graphics.
            </p>
            <p className="text-xl md:text-2xl mb-1">Installation</p>
            <p className="md:text-lg mb-8">
              Our installation team are experienced and professional. Also familiar with all kinds of aerial work
              platform, and capable of install projects at different heights and sizes.
            </p>
          </div>
        </AnimeDiv>
        <AnimeDiv className="container m-auto px-5 mb-10">
          <p className="font-semibold md:text-xl mb-8 uppercase">Full Service</p>
          <img src="/assets/img/services/img-2.png" alt="img-1" className="object-contain w-full px-5" />
        </AnimeDiv>

        <AnimeDiv className="container m-auto px-5 my:10 md:my-28">
          <Qna />
        </AnimeDiv>

        <AnimeDiv className="my-20 md:my-32">
          <EffectGrid data={data} />
        </AnimeDiv>

        <AnimeDiv className="container m-auto px-5 pb-5">
          <ContactSection />
        </AnimeDiv>
      </div>
    </>
  );
};

export default Services;
