/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';

// eslint-disable-next-line no-unused-vars
const ExpendIcon = () => (
  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
  </svg>
);

// eslint-disable-next-line no-unused-vars
const ShrinkIcon = () => (
  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
  </svg>
);

const qnaData = [
  {
    q: 'What do I need to provide before creating our business sign?',
    a: [
      'To start your signage job, we need you provide:',
      '\n',
      '1. Business Address',
      '2. Signs Design and initial ideas about your signage - What kind of signs you want?',
      '3. if your location is managed by strata council, ask for the signage criteria.',
      '\n',
      '* Don’t hesitate to contact us if you need any help during the process.',
    ],
  },
  {
    q: 'What kind of signage can your shop fabricate?',
    a: [
      'Check our projects and services page for the types of sign we fabricate or contact us if you have a special idea!',
    ],
  },
  {
    q: 'Do I have to apply for a sign permit?',
    a: [
      'Yes, for most of long term exterior commercial signs you are required to apply a sign permit by City Law (including Signage and Graphics). But there are exceptions, please contact us for more information.',
    ],
  },
  {
    q: 'What file format do I need to provide for you?',
    a: [
      'For most Signage projects, we require a vector file for production. It can be saved as PDF, EPS, or AI. If you are looking to print, a high-resolution file is needed. If you don’t have the format as required or have any question, please contact us for other solutions.',
    ],
  },
  {
    q: 'How long doe it take to finish our signage?',
    a: [
      'The duration of the projects varies, depending on the permit application time, and difficulty (size, design, onsite installation, Etc.) of the project.  However, for most signage projects will take minimum of 10 business days, and most digital printing we can finish within 3 business days.',
    ],
  },
  {
    q: 'Do you have design service?',
    a: [
      'Yes, our most talent partner at Brainchild Creatives can provide you professional design services, from Marketing, Branding, VIS to Application Design. (See our Partnership page for their portfolio). Also, our in-house designer can assist you with your basic design needs.',
    ],
  },
  {
    q: 'What is the full process of signs fabrication?',
    a: [
      'Please check our services page for the full process. However, the process might differ depending on the city and project type. Contact us to find out more about your signage process.',
    ],
  },
  {
    q: 'What is the cost of a signage?',
    a: [
      'The cost of a signage project varies depending on the design and city requirements. We will provide you with a detail quotation including all the cost of fabrication and the documents required by different Cities. (No hidden cost guaranteed)',
    ],
  },
  {
    q: 'If I already have a signage made, can you guys provide onsite survey and installation?',
    a: [
      'Yes, we can assist you to finish rest of process, including: On-site survey, Sign Permit, Safety Check according to UL Standard and Installation.',
    ],
  },
  {
    q: 'What is the UL Standard and why is it important?',
    a: [
      'Most of electrical signs (Chanel Letter Sign, Light-Box Sign and Awning) requires a safety decal label by City to pass the final inspection. Our company is UL certified electrical sign manufacture (E509252), UL ensures their marks represent the highest integrity by preventing counterfeiting.',
    ],
  },
];

// eslint-disable-next-line react/prop-types
const Question = ({ question = '', answers = '', alwaysDisplay = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  return alwaysDisplay ? (
    <div className="mb-2">
      <div className="flex space-x-3 py-3 justify-between items-center w-full">
        <p className="text-left font-semibold text-lg">Q: {question}</p>
      </div>
      {answers.map((answer, index) =>
        answer === '\n' ? (
          <br />
        ) : (
          <p className="text-left text-blue-900 text-sm">
            {index === 0 && 'A: '}
            {answer}
          </p>
        )
      )}
    </div>
  ) : (
    <div className="mb-2">
      <button
        type="button"
        className="flex space-x-3 py-3 justify-between items-center w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-left font-semibold">Q: {question}</p>
        <span>{!isOpen ? <ExpendIcon /> : <ShrinkIcon />}</span>
      </button>
      {isOpen &&
        answers.map((answer, index) =>
          answer === '\n' ? (
            <br />
          ) : (
            <p className="text-left text-blue-900 text-sm">
              {index === 0 && 'A: '}
              {answer}
            </p>
          )
        )}
    </div>
  );
};

const Qna = () => (
  <div>
    <p className="uppercase font-semibold text-sm ">commonly asked questions</p>
    <div className="md:hidden divide-y">
      {qnaData.map((qna) => (
        <Question question={qna.q} answers={qna.a} />
      ))}
    </div>
    <div className="hidden md:grid grid-cols-2 gap-20">
      <div>
        {qnaData.slice(0, 5).map((qna) => (
          <Question question={qna.q} answers={qna.a} alwaysDisplay />
        ))}
      </div>
      <div>
        {qnaData.slice(5).map((qna) => (
          <Question question={qna.q} answers={qna.a} alwaysDisplay />
        ))}
      </div>
    </div>
  </div>
);

export default Qna;
