/* eslint-disable no-console */
import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

export const SwiperContext = createContext(null);

const SwiperContextProvider = ({ children }) => {
  const [targetKey, setTargetKey] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/assets/json/ProjectData.json')
      .then((response) => response.json())
      .then((list) => {
        if (list.length > 0) {
          setData(
            list
              .filter((project) => project.imageData && project.imageData.length > 0)
              .sort((d1, d2) => (d2.order || 2) - (d1.order || 1))
              .slice(0, 5)
          );
        }
      });
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && data[0].id) {
      setTargetKey(data[0].id);
    }
  }, [data]);

  const onIndexChange = (activeIndex) => {
    if (data.length > 0) {
      setTargetKey(data[activeIndex].id);
    }
    setCurrentIndex(activeIndex);
  };

  return (
    <SwiperContext.Provider
      value={{
        data,
        targetKey,
        currentIndex,
        onIndexChange,
        setTargetKey,
      }}
    >
      {data && data.length > 0 && children}
    </SwiperContext.Provider>
  );
};

SwiperContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SwiperContextProvider;
