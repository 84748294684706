import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';
import GridCell from './GridCell';

const imageVariants = {
  hover: {
    opacity: 0.7,
    scale: 1.05,
    transition: {
      type: 'tween',
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      type: 'tween',
    },
  },
};

const EffectGrid = ({ data }) => {
  const [currentData, setCurrentData] = useState(data[0] || {});
  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentData(data[0]);
    }
  }, [data]);

  const [hoverIndex, setHoverIndex] = useState(0);
  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentData(data[hoverIndex]);
    }
  }, [hoverIndex]);

  const handleMouseOnLeave = () => setHoverIndex(0);

  return (
    <div
      className="relative grid grid-cols-2 md:grid-cols-3 bg-black h-effect-cell-3sm md:h-effect-cell-2md overflow-hidden"
      onMouseLeave={handleMouseOnLeave}
    >
      <AnimatePresence>
        <motion.img
          initial="hidden"
          animate="hover"
          exit="hidden"
          variants={imageVariants}
          key={currentData.imgSrc || ''}
          src={currentData.imgSrc || ''}
          alt={currentData.title || ''}
          className="absolute top-0 bottom-0 left-0 right-0 object-cover w-full h-effect-cell-3sm md:h-effect-cell-2md"
        />
      </AnimatePresence>
      {data.map((_data, index) => (
        <GridCell
          key={_data.title}
          data={_data}
          index={index}
          setCurrentData={setCurrentData}
          setHoverIndex={setHoverIndex}
          isOnHover={hoverIndex === index}
        />
      ))}
    </div>
  );
};

EffectGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default EffectGrid;
