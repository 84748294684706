/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactSection from '@components/contact/ContactSection';
import AnimeDiv from '@components/anime/AnimeDiv';
import SwiperHeader from '@components/layout/swiper/SwiperHeader';
import SwiperContextProvider from '@contexts/swiperContext';
import EffectGrid from '@components/grid/EffectGrid';
import ProjectCellGroup from '@components/project/ProjectCellGroup';

const Home = () => {
  const [data, setData] = useState([]);

  useEffect(async () => {
    setData(await fetch('/assets/json/EffectGridDataHome.json').then((response) => response.json()));
  }, []);

  return (
    <>
      <SwiperContextProvider>
        <SwiperHeader />
      </SwiperContextProvider>
      <AnimeDiv className="container m-auto px-5">
        <div className="my-10 md:my-32 grid lg:grid-cols-5 gap-10">
          <div className="md:col-span-2">
            <p className="text-3xl md:text-4xl mb-10 pr-20">Outshine your competitors with POWERWELL</p>
            <p className="md:text-lg mb-10 font-semibold">
              Here at Powerwell we proudly deliver top quality print and signage solutions.
            </p>
            <p className="md:text-lg mb-10 font-semibold">
              We are invested in listening to the needs of each of our clients, to deliver their unique message in a way
              that is captivating, creative and clever.
            </p>
            <Link to="/contact-us" className="text-xs mb-10 hover:text-blue-400 transition duration-150">
              CONTACT US
              <span className="verline">&nbsp;</span>
            </Link>
          </div>
          <div className="md:col-span-3 flex mb-14">
            <div className="w-1/2 overflow-hidden mr-5 md:mr-10">
              <img
                src="/assets/img/home/img-1.png"
                alt="img-1"
                className="mt-20 hover:scale-110 scale-100 transform transition origin-top duration-200"
              />
            </div>
            <div className="w-1/2 overflow-hidden">
              <img
                src="/assets/img/home/img-2.png"
                alt="img-2"
                className="mb-20 hover:scale-110 scale-100 transform transition origin-bottom duration-200"
              />
            </div>
          </div>
        </div>
      </AnimeDiv>

      <AnimeDiv className="mb-10 md:my-32">
        <EffectGrid data={data} />
      </AnimeDiv>

      <AnimeDiv className="container m-auto mb-10 px-5">
        <div className="mb-8">
          <p className="uppercase mb-1 text-xs md:text-sm">Recent</p>
          <p className="text-xl md:text-2xl font-semibold md:uppercase">Projects</p>
        </div>
        <ProjectCellGroup className="md:grid-cols-3 gap-8" />
      </AnimeDiv>

      <AnimeDiv className="container m-auto px-5 pb-5">
        <ContactSection />
      </AnimeDiv>
    </>
  );
};

export default Home;
