/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import PropTypes from 'prop-types';

const textVariants = {
  hover: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0,
      type: 'tween',
    },
  },
  hidden: {
    opacity: 0,
    y: 50,
    transition: {
      delay: 0,
      type: 'tween',
    },
  },
};

const AnimatedText = ({ text, isOpen }) => (
  <AnimatePresence exitBeforeEnter>
    {isOpen && (
      <motion.div
        initial="hidden"
        animate="hover"
        exit="hidden"
        key={text}
        variants={textVariants}
        className="text-sm mb-5 lg:text-xl text-left"
      >
        {text}
      </motion.div>
    )}
  </AnimatePresence>
);
AnimatedText.propTypes = {
  text: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const GridCell = ({ data = {}, index, isOnHover = false, setCurrentData = () => {}, setHoverIndex = () => {} }) => {
  const handleMouseOnEnter = () => {
    setCurrentData(data);
    setHoverIndex(index);
  };

  return (
    <button
      className="relative border-b-min border-l-min h-effect-cell-sm md:h-effect-cell-md text-white flex flex-col justify-end p-5"
      type="button"
      onMouseEnter={handleMouseOnEnter}
    >
      <AnimatedText text={data.des || ''} isOpen={isOnHover} />
      <p className="text-sm sm:text-xl md:text-2xl font-semibold">{data.title || ''}</p>
    </button>
  );
};

GridCell.propTypes = {
  data: PropTypes.shape().isRequired,
  index: PropTypes.any.isRequired,
  setCurrentData: PropTypes.func.isRequired,
  setHoverIndex: PropTypes.func.isRequired,
  isOnHover: PropTypes.bool,
};

export default GridCell;
