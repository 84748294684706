import React from 'react';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }

  render() {
    const { status } = this.state;
    return (
      <form onSubmit={this.submitForm} action="https://formspree.io/f/mrgoaone" method="POST" className="">
        <p>
          <label className="font-semibold">
            Your Name (required)
            <input type="text" name="name" className="mt-5 px-2 border-b-2 mb-5 w-full" />
          </label>
        </p>
        <p>
          <label className="font-semibold">
            Your Email (required)
            <input type="email" name="replyto" className="mt-5 px-2 border-b-2 mb-5 w-full" />
          </label>
        </p>
        <p>
          <label className="font-semibold">
            Your Message
            <br />
            <textarea name="message" className="mt-5 h-64 px-2 border-b-2 mb-5 w-full" />
          </label>
        </p>
        <button type="submit" className="bg-black text-white px-8 py-2">
          Send
        </button>
        {status === 'SUCCESS' ? <p /> : <p />}
      </form>
    );
  }
}
