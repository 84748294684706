/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const SwiperItem = ({ data }) => {
  return (
    <motion.img
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          opacity: 1,
          transition: {
            delay: 0.7,
            type: 'tween',
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            delay: 0.7,
            type: 'tween',
          },
        },
      }}
      src={data.imageData && data.imageData.length > 0 && data.imageData[0].image}
      alt={data.id}
      className="w-full object-cover h-my-header"
    />
  );
};

SwiperItem.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default SwiperItem;
