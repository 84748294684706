import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const ProjectCell = ({ src, address = [], text, type, id }) => {
  const history = useHistory();
  return (
    <button type="button" className="text-left" onClick={() => history.push(`/projects/${id}`)}>
      <div className="w-full h-64 mb-5 overflow-hidden">
        <img
          src={src}
          alt={text}
          className="w-full h-full hover:scale-110 scale-100 transform transition duration-200 object-cover"
        />
      </div>
      <p className="uppercase text-sm font-semibold">{text}</p>
      {address.map((line) => (
        <p className="text-sm font-semibold" key={line}>
          {line}
        </p>
      ))}
      <p className="mt-4 text-xs font-semibold">{type}</p>
    </button>
  );
};

ProjectCell.propTypes = {
  src: PropTypes.string.isRequired,
  address: PropTypes.arrayOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ProjectCell;
