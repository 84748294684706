/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { SwiperContext } from '@contexts/swiperContext';
import CustomSwiper from './CustomSwiper';
import SwiperItem from './SwiperItem';
import SwiperContainer from './SwiperContainer';

const SwiperHeader = () => {
  const { targetKey, data = [], onIndexChange } = useContext(SwiperContext);

  return (
    <div className="relative">
      <CustomSwiper
        data={data.map((d) => ({
          ...d,
          component: <SwiperItem data={d} />,
        }))}
        onIndexChange={onIndexChange}
        activeKey={targetKey.toString()}
      />
      <SwiperContainer />
    </div>
  );
};

SwiperHeader.propTypes = {};

export default SwiperHeader;
