/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AnimeDiv from '@components/anime/AnimeDiv';

const ExternalImage = (props) => {
  const [loaded, setLoaded] = useState(false);
  const { src, alt, className, placeHolderColor } = props;
  const onLoad = () => setLoaded(true);
  return (
    <>
      <img
        {...props}
        style={{ display: loaded ? 'block' : 'none' }}
        onLoad={onLoad}
        src={src}
        alt={alt}
        className={className}
      />
      {!loaded && (
        <div
          className={`${className}`}
          style={{
            background: `rgb(${placeHolderColor})`,
            minHeight: 400,
          }}
        />
      )}
    </>
  );
};

const Projects = () => {
  const { _id = '' } = useParams();
  const [detail, setDetail] = useState({});
  const [data, setData] = useState([]);

  useEffect(async () => {
    const list = await fetch('/assets/json/ProjectData.json').then((response) => response.json());
    setData(list.filter((project) => project.imageData && project.imageData.length > 0));
  }, []);

  useEffect(() => {
    if (_id && data) {
      setDetail(data.find((project) => project.id === _id));
    }
  }, [_id, data]);

  const { imageData = [], id = '', title = '', desp = '', year = '', location = '', client = '', Fabrication = '' } =
    detail || {};

  return (
    <>
      {!_id && (
        <AnimeDiv
          className="container px-5 m-auto"
          style={{
            paddingTop: 100,
            paddingBottom: 50,
          }}
        >
          <p className="mb-20 text-3xl md:w-1/2 uppercase">Outshine your competitors with powerwell</p>
          <AnimeDiv className="grid md:grid-cols-2 lg:grid-cols-3 gap-10">
            {data
              .sort((d1, d2) => (d2.order || 2) - (d1.order || 1))
              .map((project) => (
                <Link key={project.id} to={`/projects/${project.id}`}>
                  <div className="w-full h-64 overflow-hidden">
                    <ExternalImage
                      src={
                        (project.imageData &&
                          project.imageData.length > 0 &&
                          project.imageData[0] &&
                          project.imageData[0].image) ||
                        ''
                      }
                      className="w-full h-full hover:scale-110 scale-100 transform transition duration-200 object-cover"
                      alt={project.id}
                      placeHolderColor={
                        project.imageData &&
                        project.imageData.length > 0 &&
                        project.imageData[0] &&
                        project.imageData[0].default_color
                          ? project.imageData[0].default_color.toString()
                          : ''
                      }
                    />
                  </div>
                </Link>
              ))}
          </AnimeDiv>
        </AnimeDiv>
      )}

      {_id && (
        <div
          className="mb-32 pb-5"
          style={{
            paddingTop: 100,
            paddingBottom: 50,
          }}
        >
          <AnimeDiv>
            <div className="container">
              <p className="px-5 md:px-28 my-8 md:my-10 text-xl md:text-3xl uppercase">{title}</p>
            </div>
            <ExternalImage
              src={(imageData && imageData.length > 0 && imageData[0].image) || ''}
              alt={`${id}-header`}
              className="w-full md:h-my-header object-cover"
              placeHolderColor={
                imageData && imageData.length > 0 && imageData[0] && imageData[0].default_color
                  ? imageData[0].default_color.toString()
                  : ''
              }
            />
          </AnimeDiv>
          <AnimeDiv className="container px-5 mt-10 md:mt-20 m-auto grid md:grid-cols-5">
            <p className="md:col-span-3 mb-5">{desp}</p>
            <div className="md:col-span-2 md:mx-10 font-semibold">
              <p>Year: {Number.parseInt(year, 10)}</p>
              <p>Location: {location}</p>
              <p>Client: {client}</p>
              <p>Fabrication: {Fabrication}</p>
            </div>
          </AnimeDiv>
          <AnimeDiv className="container md:col-span-2 px-5 m-auto">
            {imageData.slice(1, imageData.length).map((img, index) => (
              <ExternalImage
                key={img.image || ''}
                src={img.image || ''}
                alt={`${id}-img-${index}`}
                className="w-full my-8"
                placeHolderColor={img.default_color ? img.default_color.toString() : ''}
              />
            ))}
          </AnimeDiv>
        </div>
      )}
    </>
  );
};

export default Projects;
