import React, { useState, useEffect } from 'react';
import Header from '@components/layout/header/Header';
import AnimeDiv from '@components/anime/AnimeDiv';
import ContactForm from '@components/contact/ContactForm';

const HEADER = {
  src: '/assets/img/partners/partners-header.png',
  title: [
    'Through years of experiences, we forged strong relationship with our clients and light up more than 1,600 businesses across Greater Vancouver. ',
  ],
};

const Partners = () => {
  const { src, title } = HEADER;
  const [partners, setPartners] = useState([]);

  useEffect(async () => {
    setPartners(await fetch('/assets/json/PartnerData.json').then((response) => response.json()));
  }, []);

  return (
    <>
      <Header src={src} title={title} />
      <AnimeDiv className="container m-auto px-5 py-20">
        <p className="font-semibold text-xl">Partners</p>
      </AnimeDiv>
      <AnimeDiv className="container m-auto px-5 grid grid-cols-3 gap-2 md:gap-10 mb-32">
        {partners.map((link, index) => (
          <div key={Math.random().toString()} className="p-5 md:p-8 xl:p-28 m-auto">
            <a href={link} target="_blank" rel="noreferrer">
              <img src={`/assets/img/partners/logo-${index + 1}.png`} alt="index" className="object-cover" />
            </a>
          </div>
        ))}
      </AnimeDiv>
      <AnimeDiv
        style={{
          backgroundImage: "url('/assets/img/partners/img-1.png')",
        }}
        alt="img-1"
        className="bg-cover w-full p-10"
      >
        <div className="m-auto md:mr-0 bg-white md:w-1/3 p-8 md:p-10">
          <p className="text-xl mb-6">Would you like to discuss a project with us today?</p>
          <p className="text-sm text-gray-400 mb-10">
            Come in and have a chat with us. We look forward to working with you.
          </p>
          <ContactForm />
        </div>
      </AnimeDiv>
    </>
  );
};

export default Partners;
